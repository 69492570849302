// ThemeToggle.js
import React, { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import { SunIcon, MoonIcon } from "@heroicons/react/20/solid";

function ThemeToggle() {
  // State to store whether dark mode is enabled
  const [enabled, setEnabled] = useState(false);

  // On component mount, check local storage for theme preference
  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");

    if (storedTheme === "dark") {
      setEnabled(true);
      document.documentElement.classList.add("dark");
    } else {
      setEnabled(false);
      document.documentElement.classList.remove("dark");
    }
  }, []);

  // Function to toggle theme
  const toggleTheme = () => {
    setEnabled((prevEnabled) => {
      const newEnabled = !prevEnabled;

      if (newEnabled) {
        document.documentElement.classList.add("dark");
        localStorage.setItem("theme", "dark");
      } else {
        document.documentElement.classList.remove("dark");
        localStorage.setItem("theme", "light");
      }

      return newEnabled;
    });
  };

  return (
    <Switch
      checked={enabled}
      onChange={toggleTheme}
      className={`relative inline-flex h-6 w-12 items-center transition rounded-full ${
        enabled ? "bg-gray-800" : "bg-gray-200"
      }`}
    >
      {/* Sun icon (light mode) */}
      <span
        className={`absolute right-1 transition-opacity duration-200 ${
          enabled ? "opacity-0" : "opacity-100"
        }`}
      >
        <MoonIcon className="h-4 w-4 text-yellow-500 -rotate-90" />
      </span>
      {/* Moon icon (dark mode) */}
      <span
        className={`absolute right-7 transition-opacity duration-200 ${
          enabled ? "opacity-100" : "opacity-0"
        }`}
      >
        <SunIcon className="h-4 w-4 text-yellow-400 " />
      </span>
      {/* Toggle knob */}
      <span
        className={`shadow inline-block h-6 w-6 transform rounded-full bg-white transition-transform duration-200 ease-in-out ${
          enabled ? "translate-x-6 bg-gray-300" : "translate-x-0"
        }`}
      />
    </Switch>
  );
}

export default ThemeToggle;
