import axios from "axios";

// Base URL configuration
const baseURL =
  process.env.REACT_APP_AUTH_API_BASE_URL || "http://localhost:5000";

// Create an Axios instance
const authClient = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add an interceptor to handle requests
authClient.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add an interceptor to handle responses
authClient.interceptors.response.use(
  (response) => {
    return response.data; // Return only the data from the response
  },
  (error) => {
    // Handle errors globally if needed
    console.error("API error:", error.response || error.message);
    return Promise.reject(error);
  }
);

export default authClient;
