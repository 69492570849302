import React, { useState } from "react";
import {
  Field,
  Fieldset,
  Input,
  Label,
  Legend,
  Description,
} from "@headlessui/react";
import { Checkbox } from "@headlessui/react";
import { clsx } from "clsx";
import authClient from "../../../client/auth";

function LoginForm() {
  const [rememberMe, setRememberMe] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission
    const formData = new FormData(event.target); // Collect form data
    const data = Object.fromEntries(formData.entries()); // Convert to an object

    try {
      const response = await authClient.post("/login", {
        email: data.username,
        password: data.password,
      });
      console.log("Login successful:", response.data);
      // Perform actions after successful login (e.g., navigate to dashboard)
    } catch (error) {
      console.error("Login failed:", error);
      setIsError(true);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset className="space-y-6 rounded-xl bg-bg-primary p-6 sm:p-10 text-text-primary shadow">
        <div className="flex w-full items-center justify-between">
          <Legend className="text-base/7 font-semibold text-text-secondary">
            Login
          </Legend>
          {isError ? (
            <div className="text-sm/6 text-text-error">
              Error: Wrong Email or Password
            </div>
          ) : (
            <></>
          )}
        </div>
        {/* Email/Username Field */}
        <Field>
          <Label className="text-sm/6 font-medium text-text-secondary">
            Email
          </Label>
          <Input
            id="username"
            name="username"
            className={clsx(
              "mt-3 block w-full rounded-lg border-none bg-bg-secondary py-1.5 px-3 text-sm/6 text-text-primary",
              "focus:outline-none focus:ring-2 focus:ring-focus-primary"
            )}
            type="text"
            placeholder="Enter your email or username"
            required
          />
        </Field>
        {/* Password Field */}
        <Field>
          <Label className="text-sm/6 font-medium text-text-secondary">
            Password
          </Label>
          <Input
            id="password"
            name="password"
            className={clsx(
              "mt-3 block w-full rounded-lg border-none bg-bg-secondary py-1.5 px-3 text-sm/6 text-text-primary",
              "focus:outline-none focus:ring-2 focus:ring-focus-primary"
            )}
            type="password"
            placeholder="Enter your password"
            required
          />
          <Description className="mt-2 text-sm/6 text-text-tertiary">
            <a href="#" className="hover:underline">
              Forgot your password?
            </a>
          </Description>
        </Field>
        {/* Remember Me and Submit */}
        <div className="flex items-center justify-between">
          <div className="flex items-center ">
            <Checkbox
              checked={rememberMe}
              onChange={setRememberMe}
              className="group block size-4 rounded border border-border-primary bg-bg-secondary data-[checked]:bg-bg-primary"
            >
              <svg
                className="stroke-text-tertiary opacity-0 group-data-[checked]:opacity-100"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M3 8L6 11L11 3.5"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Checkbox>
            <div className="ml-2 text-sm/6 text-text-tertiary">Remember me</div>
          </div>
          <button
            type="submit"
            className={clsx(
              "inline-flex justify-center rounded-lg bg-button-primary px-4 py-2 text-sm/6 font-medium text-text-button",
              "hover:bg-button-hover focus:outline-none focus:ring-2 focus:ring-focus-primary"
            )}
          >
            Submit
          </button>
        </div>
      </Fieldset>
    </form>
  );
}

export default LoginForm;
