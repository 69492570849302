import React from "react";
import AuthPage from "./context/auth/AuthPage";
import ThemeToggle from "./appProvider/appComponents/ThemeToggle";

function App() {
  return (
    <div className="App p-12 dark:bg-black flex justify-center">
      <div className="max-w-[1000px]">
        <ThemeToggle />
        <AuthPage />
      </div>
    </div>
  );
}

export default App;
