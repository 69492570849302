import React from "react";
import LoginForm from "./components/LoginForm";
import SignupForm from "./components/SignupForm";

export default function AuthPage() {
  return (
    <div>
      <LoginForm />
      <SignupForm />
    </div>
  );
}
